export class ActionState {
  moduleName: string;
  enableFileMenu: boolean;
  enableEditMenu: boolean;
  canAdd: boolean;
  canSave: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canDuplicate: boolean;
  canUndo: boolean;
  canRedo: boolean;

  constructor(moduleName: string, enableFileMenu: boolean, enableEditMenu: boolean, canAdd: boolean, canSave: boolean, canEdit: boolean,
    canDelete: boolean, canDuplicate: boolean, canUndo: boolean, canRedo: boolean) {
    this.moduleName = moduleName;
    this.enableFileMenu = enableFileMenu;
    this.enableEditMenu = enableEditMenu;
    this.canAdd = canAdd;
    this.canSave = canSave;
    this.canEdit = canEdit;
    this.canDelete = canDelete;
    this.canDuplicate = canDuplicate;
    this.canUndo = canUndo;
    this.canRedo = canRedo;
  }
}