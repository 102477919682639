import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { SERVER_API_URL } from 'app/app.constants';
import { createRequestOption, Pagination } from 'app/shared/util/request-util';
import { IUser } from './user.model';
import { Authority } from 'app/shared/constants/authority.constants';
import { ChangePassword } from 'app/model/entity/change-password';

@Injectable({ providedIn: 'root' })
export class UserService {
  public resourceUrl = SERVER_API_URL + 'services/uaa/api/users';

  constructor(private http: HttpClient) {}

  create(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(this.resourceUrl, user);
  }

  update(user: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.resourceUrl, user);
  }

  find(login: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.resourceUrl}/${login}`);
  }

  query(req?: Pagination): Observable<HttpResponse<IUser[]>> {
    const options = createRequestOption(req);
    return this.http.get<IUser[]>(this.resourceUrl, { params: options, observe: 'response' });
  }

  delete(login: string): Observable<{}> {
    return this.http.delete(`${this.resourceUrl}/${login}`);
  }

  authorities(): Observable<string[]> {
    return of([Authority.ADMIN, Authority.USER]);
  }

  checkExistByUserId(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(`${this.resourceUrl}/checkExistByUserId`, user);
  }
  changePassword(changePassword: ChangePassword): Observable<string> {
    return this.http.post<string>(`${SERVER_API_URL}services/uaa/api/account/change-password`, changePassword);
  }
  checkConfirmCurrentPassword(changePassword: ChangePassword): Observable<string> {
    return this.http.post<string>(`${SERVER_API_URL}services/uaa/api/account/check-confirm-password`, changePassword);
  }
}
