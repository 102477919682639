import { Injectable } from '@angular/core';
import { LANGUAGES } from 'app/core/language/language.constants';

@Injectable({ providedIn: 'root' })
export class LanguageHelper {
  constructor() {}

  getAll(): string[] {
    return LANGUAGES;
  }
}
