import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { LoginService } from 'app/core/login/login.service';
import { Common } from 'app/model/entity/common';
import { SaveMainStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { UserService } from 'app/service/user.service';
import { AppState } from 'app/store/app.state';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private router: Router,
    public readonly store: Store<AppState>,
    private userService: UserService
  ) {
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(null, (err: HttpErrorResponse) => {
        if (err.status === 401 && err.url && !err.url.includes('auth/login')) {
          if (err.url.includes(this.loginService.logoutUrl())) {
            this.loginService.logoutInClient();
            return;
          }
          this.userService.updateUserLogout(this.commonObject?.userIdString).subscribe();
          alert('Session timed out. Reload to login again.');
          sessionStorage.clear();
          this.store.dispatch(
            new SaveMainStateAction({
              common: undefined
            })
          );
          this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
          this.loginService.logout();
          //this.router.navigate(['']);
          //this.loginModalService.open();
          window.location.reload();
        }
      })
    );
  }
}
