import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'app/app.constants';
import { Constant } from 'app/config/constants';
import { Login } from 'app/core/login/login.model';
import { ExecutingService } from 'app/service/executing.service';
import { Observable } from 'rxjs';

export const LOGOUT_URL = SERVER_API_URL + 'auth/logout';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
  constructor(private http: HttpClient, private executingService: ExecutingService) {}

  login(credentials: Login): Observable<any> {
    return this.http.post(SERVER_API_URL + 'auth/login', credentials, { headers: { [Constant.TENANT_HEADER]: credentials.accountId } });
  }

  logout(): Observable<{}> {
    return this.http.post(LOGOUT_URL, null);
  }
}
