export interface IUser {
  id?: any;
  userId?: string;
  fullName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: string[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
  role?: string;
  no?: string;
  /**
   * role id
   */
  roleId?: Number;
}

export class User implements IUser {
  constructor(
    public id?: any,
    public userId?: string,
    public fullName?: string,
    public email?: string,
    public activated?: boolean,
    public langKey?: string,
    public authorities?: string[],
    public createdBy?: string,
    public createdDate?: Date,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Date,
    public password?: string,
    public role?: string,
    public no?: string,
    public roleId?: Number
  ) {}
  isSelected?: boolean;
}
