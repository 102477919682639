import { JhiAlertService } from 'ng-jhipster';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ExecutingService } from 'app/service/executing.service';
import { Constant } from 'app/config/constants';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private alertService: JhiAlertService, private executingService: ExecutingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleHttp = next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let alert: string | null = null;
          event.headers.keys().forEach(entry => {
            if (entry.toLowerCase().endsWith('app-alert')) {
              alert = event.headers.get(entry);
            }
          });
          if (alert) {
            this.alertService.success(alert);
          }
        }
      }),
      finalize(() => {
        if (!Constant.SKIP_WAITING_URLS.some(url => request.url.includes(url))) {
          this.executingService.executed();
        }
      })
    );
    const httpEvent = defer(() => {
      if (!Constant.SKIP_WAITING_URLS.some(url => request.url.includes(url))) {
        this.executingService.executing();
      }
      return handleHttp;
    });
    return httpEvent;
  }
}
